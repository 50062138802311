import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { decode } from 'jsonwebtoken'
import api from '@/infra/api'
import { AccountPersistentStore } from '@/main/store'
import { IPurchaseOrdersResponse } from './useOrderList'
import { IStandardResponse } from '@/main/interfaces'
import { TApiOrderUserAttributed } from './iPurchasesApiResponse'
import _ from 'lodash'

interface IUpdateAttribution {
  attribution: boolean
  ordersIds: Array<number | string>
  purchaseId: string
}

interface IUpdateQueryCacheParams extends IUpdateAttribution {
  queryClient: ReturnType<typeof useQueryClient>
  resaleId: string
  user: TApiOrderUserAttributed
}

const updateQueryCache = ({
  queryClient,
  resaleId,
  purchaseId,
  ordersIds,
  attribution,
  user,
}: IUpdateQueryCacheParams) => {
  try {
    const queryCache = queryClient.getQueriesData({ queryKey: ['purchases', resaleId] })
    const queryKey = queryCache?.[queryCache.length - 1]?.[0]
    queryClient.setQueryData(queryKey, (oldData: any) => {
      if (!oldData) return oldData
      const newData: {
        pages: AxiosResponse<IPurchaseOrdersResponse>[]
        pageParams: any[]
      } = _.cloneDeep(oldData)

      const page = newData.pages.find((page) =>
        page.data.valor.compras.some((purchase) => String(purchase.idCompra) === String(purchaseId)),
      )
      if (!page) return oldData

      const purchase = page.data.valor.compras.find((p) => String(p.idCompra) === String(purchaseId))
      const usersIds = purchase.pedidos.map((pedido) => pedido.responsavel?.id)

      // Atualiza a atribuição de usuário para cada pedido
      purchase.pedidos = purchase.pedidos.map((o) =>
        ordersIds.includes(o.idPedido) ? { ...o, responsavel: attribution ? user : null } : o,
      )

      // Adiciona o usuário se não estiver na lista de responsáveis
      if (attribution && purchase.usuariosResponsaveis.every((usuario) => usuario.id !== user.id)) {
        purchase.usuariosResponsaveis.push(user)
      }

      // Remove o usuário se ele não for responsável por nenhum pedido
      if (!attribution) {
        usersIds.forEach((userId) => {
          if (purchase.pedidos.every((pedido) => pedido.responsavel?.id !== userId)) {
            const index = purchase.usuariosResponsaveis.findIndex((usuario) => usuario.id === userId)
            if (index > -1) purchase.usuariosResponsaveis.splice(index, 1)
          }
        })
      }

      return newData
    })
  } catch (error) {
    throw new Error('Fail updating list', { cause: error })
  }
}

export const useOrderAttribution = () => {
  const queryClient = useQueryClient()
  const account = useAtomValue(AccountPersistentStore)
  const resaleId = decode(account.auth.accessToken)?.resaleId

  const changeAttribution = useMutation({
    mutationFn: (payload: IUpdateAttribution) =>
      api.patch<IStandardResponse<TApiOrderUserAttributed>>('/revenda-pedidos-bff/pedido/alterarAtribuicao', {
        atribuir: payload.attribution,
        pedidos: payload.ordersIds,
      }),
    onSuccess: (response, payload) => {
      updateQueryCache({
        queryClient,
        resaleId,
        user: response.data.valor,
        ...payload,
      })
    },
  })

  return {
    changeAttribution,
    isLoading: changeAttribution.isLoading,
  }
}