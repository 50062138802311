import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Box, Button } from '@stationkim/front-ui'
import { ButtonsContainer, StyledAccordion, StyledButton, StyledDivider } from './styles'
import { EAdministrativeFeeValueType, EBenefitType, ECompanyStatus, EDeskFeeType, EFeeCalculationType, EParameterStatus, ETipoTaxaEntrega } from '@/main/enums'
import { CompanyContext } from '../../consultCompany'
import { IssFee } from './components/issFee/issFee'
import { ParameterDialogEdition } from './components/parameterDialogEdition/parameterDialogEdition'
import { useCompanyParametersMutation } from '../../hooks/useCompanyParametersMutation'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { AdministrativeFee, TAdministrativeFee } from '../../../components/administrativeFee/administrativeFee'
import { SuccessFee } from '../../../components/successFee/successFee'
import { DeliveryFee, TDeliveryFee } from '../../../components/deliveryFee/deliveryFee'

export type TCompanyParametersForm = {
  successFee: string
  issFee: boolean
  issIncidences: number[]
  administrativeFees: TAdministrativeFee[]
  newBalanceProjection: boolean
  deliveryFeeType: ETipoTaxaEntrega | ''
  deliveryFees: TDeliveryFee[]
  orderFeeValue?: string
  deliveryFeeByOrderId?: string
}

const initialValues: TCompanyParametersForm = {
  successFee: '',
  issFee: false,
  administrativeFees: [],
  deliveryFees: [],
  deliveryFeeType: ETipoTaxaEntrega.Cartao,
  issIncidences: [],
  newBalanceProjection: false,
}

export const Parameters = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const { company, isLoading, isFetching } = useContext(CompanyContext)
  const { editParameters } = useCompanyParametersMutation()
  //so pode ditar iss com empresa aprovada ou aguardando aprovacao
  const isIssAvailable = company?.issInfo?.status === EParameterStatus.Active
  const isWithMigrationError = company?.status === ECompanyStatus.MigrationError
  const isIssConfigurationAvailable = [ECompanyStatus.Approved, ECompanyStatus.WaitingApproval].includes(
    company?.status,
  )

  const form = useForm<TCompanyParametersForm>({
    defaultValues: initialValues,
    mode: 'onChange',
  })

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, errors },
  } = form

  const issFee = watch('issFee')

  const onSubmit: SubmitHandler<TCompanyParametersForm> = (data) => {
    if (!isConfirmationDialogOpen) setIsConfirmationDialogOpen(true)
    else {
      if (!company) return
      const deliveryFees = data.deliveryFees.map((fee: any) => ({
        benefitType: EBenefitType.Transport,
        calculationType: EFeeCalculationType.Item,
        valueType: EAdministrativeFeeValueType.Fixed,
        itemType: EDeskFeeType.delivery,
        active: data.deliveryFeeType === ETipoTaxaEntrega.Cartao,
        id: fee.id,
        quantityType: fee.valueType,
        startRange: Number(fee.initialRange),
        endRange: Number(fee.finalRange),
        value: Number(fee.feeValue) || 0,
      }))
      deliveryFees.push({
        benefitType: EBenefitType.Transport,
        calculationType: EFeeCalculationType.Order,
        valueType: EAdministrativeFeeValueType.Fixed,
        itemType: EDeskFeeType.delivery,
        active: data.deliveryFeeType === ETipoTaxaEntrega.Pedido,
        value: Number(data.orderFeeValue) || 0,
        startRange: 0,
        endRange: 0,
        id: data.deliveryFeeByOrderId,
        quantityType: 0
      })
      const administrativeFees = data.administrativeFees.map((fee) => ({
        id: company.administrativeFees.find(({ benefit }) => benefit === fee.benefit)?.id,
        benefitType: fee.benefit,
        calculationType: fee.calculationType,
        quantityType: fee.valueType,
        valueType: fee.valueType, //?, todo
        startRange: Number(fee.initialRange),
        endRange: Number(fee.finalRange),
        value: Number(fee.feeValue),
        itemType: EDeskFeeType.administrative,
      }))

      const allFees = [...deliveryFees, ...administrativeFees]
      const payload = {
        description: 'description',
        successesFee: Number(data.successFee),
        fees: allFees.map((fee: any) => {
          const apiFee = ({
            ...fee,
            quantityType: 1,
            "value": Number(fee.value),
          })

          if (fee.initialRange !== undefined) apiFee.startRange = Number(fee.initialRange)
          if (fee.finalRange !== undefined) apiFee.endRange = Number(fee.finalRange)

          return apiFee
        }),
        companyId: company.id,
        issActive: data.issFee,
        issFee: data?.issIncidences?.reduce((final, actual) => final + actual, 0) || 0,
        slipFee: 0, //endpoint requirement
        successFee: Number(data?.successFee || 0),
        newBalanceProjection: data.newBalanceProjection,
      }
      editParameters.mutate(
        payload,
        {
          onSuccess: () => {
            setIsConfirmationDialogOpen(false)
            form.reset(form.getValues())
            queryClient.invalidateQueries({ queryKey: ['company'] })
          },
        },
      )
    }
  }

  const handleCancel = () => {
    if (isDirty) reset()
    else navigate('/empresas')
  }

  const handleCancelParameterEdition = () => setIsConfirmationDialogOpen(false)
  const handleFormSave = handleSubmit(onSubmit)

  useEffect(() => {
    if (company)
      reset({
        ...initialValues,
        successFee: company?.successFee || '0',
        issFee: company.issInfo?.isActive,
        issIncidences: company.issInfo?.issIncidences,
        administrativeFees: company.administrativeFees as TAdministrativeFee[],
        newBalanceProjection: company.newBalanceProjection || false,
        deliveryFeeByOrderId: company.deliveryFeeByOrderId,
        deliveryFees: company.deliveryFees,
        deliveryFeeType: company.deliveryFeeType,
        orderFeeValue: company.orderFeeValue,
      })
  }, [company, reset])

  return (
    <Box>
      <form id='company-parameters-form' onSubmit={handleFormSave}>
        {isIssAvailable && (
          <>
            <IssFee
              errors={errors}
              issInfo={company?.issInfo}
              isVisible={issFee}
              control={control}
              disabled={!isIssConfigurationAvailable}
              isLoading={isLoading}
            />
            <StyledDivider />
          </>
        )}
        <StyledAccordion defaultExpanded label='Taxas administrativas'>
          <AdministrativeFee form={form} disabled={isWithMigrationError} isLoading={isLoading || isFetching} />
        </StyledAccordion>
        <StyledDivider />
        <StyledAccordion defaultExpanded label='Taxa de sucesso'>
          <SuccessFee form={form} isLoading={isLoading} />
        </StyledAccordion>
        <StyledDivider />
        <StyledAccordion defaultExpanded label='Taxa de entrega'>
          <DeliveryFee form={form} isLoading={isLoading} />
        </StyledAccordion>
        <ButtonsContainer sx={{ gap: isDirty ? '16px' : 0 }}>
          <Button variant='outlined' onClick={handleCancel}>
            {isDirty ? 'Cancelar' : 'Voltar'}
          </Button>
          <StyledButton visible={isDirty} variant='contained' type='submit' disabled={isLoading}>
            Salvar
          </StyledButton>
        </ButtonsContainer>
      </form>
      <ParameterDialogEdition
        open={isConfirmationDialogOpen}
        onCancel={handleCancelParameterEdition}
        onConfirm={handleFormSave}
        isLoading={editParameters.isLoading}
      />
    </Box>
  )
}
