import { FORM_MESSAGES } from '@/main/utils/constants'
import { checkIfRangesOverlap } from '@/main/utils/functions'
import { ControllerProps } from 'react-hook-form'
import { TForm } from './deliveryFee'

const rangeValidate = (
  index: number,
  range: 'initialRange' | 'finalRange' = 'initialRange',
): ControllerProps<TForm>['rules']['validate'] => ({
  isSmallerThanFinal: (value, formValues) => {
    if (range === 'finalRange')
      return Number(value) > Number(formValues.deliveryFees[index][range])
        ? 'O inicio deve ser menor que o final'
        : true
    return Number(value) < Number(formValues.deliveryFees[index][range]) ? 'O final deve ser maior que o inicio' : true
  },
  isOverlapping: (_value, formValues) => {
    const actualInterval = formValues.deliveryFees[index]
    return formValues.deliveryFees
      .filter((_fee, i) => index !== i)
      .some((fee) =>
        checkIfRangesOverlap(
          { initial: Number(actualInterval.initialRange), final: Number(actualInterval.finalRange) },
          { initial: Number(fee.initialRange), final: Number(fee.finalRange) },
        ),
      )
      ? 'Há uma sobreposição de intervalos'
      : true
  },
})

export const rules = (index: number) => ({
  initialRange: {
    required: FORM_MESSAGES.required,
    validate: rangeValidate(index, 'finalRange'),
  },
  finalRange: {
    required: FORM_MESSAGES.required,
    validate: rangeValidate(index),
  },
  feeValue: {
    required: FORM_MESSAGES.required,
  },
})
