import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useAtom, useAtomValue } from 'jotai'
import { orderIdAtom } from 'main/store'
import api from '@/infra/api'
import { atomWithStorage } from 'jotai/utils'

export const generatingPixAtom = atomWithStorage('generating-pix', false)

export const useGeneratePix = () => {
  const [, setGenerating] = useAtom(generatingPixAtom)

  const orderId = useAtomValue(orderIdAtom)

  const { mutate } = useMutation<AxiosResponse<any>>({
    mutationKey: ['regenerate', orderId],
    mutationFn: () => orderId !== '' && api.patch(`resale-bff/orders/${orderId}/regenerate-pix`),
    onSuccess() {
      setGenerating(true)
    },
  })

  return {
    generatePix: mutate,
  }
}
