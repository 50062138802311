import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MaskedTextField,
  Select,
} from '@stationkim/front-ui'
import { EFeeCalculationType, EAdministrativeFeeValueType, EBenefitType } from '@/main/enums'
import { checkIfRangesOverlap } from '@/main/utils/functions'
import { TAdministrativeFee } from '../administrativeFee'

export interface AddFeeDialogProps extends DialogProps {
  open: boolean
  onClose?: () => void
  onConfirm?: (data: TAdministrativeFee) => void
  defaultValues?: TAdministrativeFee
  existentFees?: TAdministrativeFee[]
}

const benefitsOptions = [{ label: 'Vale transporte eletrônico', value: EBenefitType.Transport }]

const feeCalculationTypeOptions = [
  { label: 'Colaborador', value: EFeeCalculationType.Item },
  { label: 'Pedido', value: EFeeCalculationType.Order },
]

const feeValueTypeOptions = [
  { label: 'Valor', value: EAdministrativeFeeValueType.Fixed },
  { label: 'Percentual', value: EAdministrativeFeeValueType.Percentual },
]

const initialValues = {
  benefit: EBenefitType.Transport,
  calculationType: '',
  valueType: '',
  initialRange: '',
  finalRange: '',
  feeValue: '',
} as unknown as TAdministrativeFee

export const AddFeeDialog = ({
  open,
  onConfirm,
  onClose,
  defaultValues = initialValues,
  existentFees = [],
  ...props
}: AddFeeDialogProps) => {
  const { control, handleSubmit, watch, trigger } = useForm<TAdministrativeFee>({
    mode: 'onChange',
    defaultValues: defaultValues,
  })

  const valueType = watch('valueType')
  const isFixedValue = valueType === EAdministrativeFeeValueType.Fixed
  const onSubmit: SubmitHandler<TAdministrativeFee> = (data) => onConfirm(data)

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' {...props}>
      <form
        id='add-administrative-fee'
        onSubmit={(e) => {
          e.stopPropagation()
          return handleSubmit(onSubmit)(e)
        }}
      >
        <DialogTitle>{defaultValues !== initialValues ? 'Editar' : 'Adicionar'} Taxa</DialogTitle>
        <DialogContent sx={{ margin: '32px 0' }}>
          <Grid container spacing='16px'>
            <Grid item xs={6}>
              <Controller
                control={control}
                name='benefit'
                render={({ field: { value, onChange } }) => (
                  <Select label='Benefício' value={value} onChange={onChange} blocked options={benefitsOptions} />
                )}
              />
            </Grid>
            <Grid item xs />
            <Grid item xs={6}>
              <Controller
                control={control}
                name='calculationType'
                rules={{ required: 'Selecione como calcular' }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label='Calcular por'
                    options={feeCalculationTypeOptions}
                    placeholder='Selecione'
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                name='initialRange'
                rules={{
                  required: 'Informe um valor inicial',
                  validate: {
                    isSmallerThanFinal: (value, formValues) =>
                      Number(value) > Number(formValues.finalRange) ? 'O inicio deve ser menor que o final' : true,
                    isOverlapping: (value, formValues) =>
                      existentFees
                        .filter(
                          (fee) =>
                            fee.benefit === formValues.benefit && fee.initialRange !== defaultValues?.initialRange,
                        )
                        .some((fee) =>
                          checkIfRangesOverlap(
                            { initial: Number(value), final: Number(formValues.finalRange) },
                            { initial: Number(fee.initialRange), final: Number(fee.finalRange) },
                          ),
                        )
                        ? 'Há uma sobreposição de intervalos'
                        : true,
                  },
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <MaskedTextField
                    mask={{ mask: Number }}
                    placeholder='00'
                    label='Intervalo inicial'
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                      trigger('finalRange')
                    }}
                    error={Boolean(error)}
                    helperText={error?.message}
                    size='large'
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                name='finalRange'
                rules={{
                  required: 'Informe um valor final',
                  validate: {
                    isBiggerThanInitial: (value, formValues) =>
                      Number(value) < Number(formValues.initialRange) ? 'O final deve ser maior que o início' : true,
                    isOverlapping: (value, formValues) =>
                      existentFees
                        .filter(
                          (fee) =>
                            fee.benefit === formValues.benefit && fee.initialRange !== defaultValues?.initialRange,
                        )
                        .some((fee) =>
                          checkIfRangesOverlap(
                            { initial: Number(formValues.initialRange), final: Number(value) },
                            { initial: Number(fee.initialRange), final: Number(fee.finalRange) },
                          ),
                        )
                        ? 'Há uma sobreposição de intervalos'
                        : true,
                  },
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <MaskedTextField
                    mask={{ mask: Number }}
                    //unmask
                    placeholder='00'
                    label='Intervalo final'
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                      trigger('initialRange')
                    }}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name='valueType'
                rules={{ required: 'Selecione o tipo de valor' }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    label='Categoria'
                    options={feeValueTypeOptions}
                    placeholder='Selecione'
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                name='feeValue'
                rules={{ required: 'Informe o valor da taxa' }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <MaskedTextField
                    mask={isFixedValue ? 'monetary' : 'percentual'}
                    placeholder={isFixedValue ? 'R$ 00,00' : '00,00 %'}
                    label='Valor da Taxa'
                    unmask
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ gap: '16px' }}>
          <Button variant='outlined' fullWidth type='button' sx={{ filter: 'grayscale(100)' }} onClick={onClose}>
            Cancelar
          </Button>
          <Button variant='contained' fullWidth type='submit' form='add-administrative-fee'>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
