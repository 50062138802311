import {
  EFeeCalculationType,
  EAdministrativeFeeValueType,
  EBenefitType,
  EStatusCompra,
  EStatusPedido,
  ETipoPagamento,
  ETipoPedido,
} from '../enums'

export const ORDER_PAYMENT_TYPES = {
  [ETipoPagamento.Gratuito]: 'Gratuito',
  [ETipoPagamento.Boleto]: 'Boleto',
  [ETipoPagamento.Pix]: 'Pix',
  [ETipoPagamento.CarteiraSK]: 'Carteira Digital',
  [ETipoPagamento.CarteiraOperadora]: 'Conta Corrente',
}

export const ORDER_STATUS = {
  [EStatusPedido.NoCarrinho]: 'Rascunho',
  [EStatusPedido.Efetivando]: 'Efetivando',
  [EStatusPedido.Efetivado]: 'Processando Pagamento',
  [EStatusPedido.AguardandoProcessamento]: 'Aguardando Processamento',
  [EStatusPedido.EmProcessamento]: 'Em Processamento',
  [EStatusPedido.EmAndamento]: 'Processamento Operadora',
  [EStatusPedido.DisponivelRetirada]: 'Disponível para Retirada',
  [EStatusPedido.Entrega]: 'Em Entrega',
  [EStatusPedido.Finalizado]: 'Concluído',
  [EStatusPedido.Cancelando]: 'Cancelando',
  [EStatusPedido.Cancelado]: 'Cancelado',
}

export const ORDER_TYPES = {
  [ETipoPedido.PrimeiraViaVtDealer]: '1 via',
  [ETipoPedido.SegundaViaVtDealer]: '2 via',
  [ETipoPedido.RecargaVtDealer]: 'Dealer',
  [ETipoPedido.RecargaVtBroker]: 'Broker',
  [ETipoPedido.Deposito]: 'Deposito',
  [ETipoPedido.Compra]: 'Compra',
}

export const PURCHASE_STATUS = {
  [EStatusCompra.Carrinho]: 'Rascunho',
  [EStatusCompra.Efetivando]: 'Efetivando',
  [EStatusCompra.ProcessandoFormaPagamento]: 'Processando Pagamento',
  [EStatusCompra.AguardandoPagamento]: 'Aguardando Pagamento',
  [EStatusCompra.EmAndamento]: 'Em Andamento',
  [EStatusPedido.Finalizado]: 'Concluído',
  [EStatusCompra.Cancelando]: 'Cancelando',
  [EStatusCompra.Cancelado]: 'Cancelado',
}

export const BENEFIT = {
  [EBenefitType.Transport]: 'Vale transporte eletrônico',
  [EBenefitType.TransportPaper]: 'Vale transporte de papel',
  [EBenefitType.Food]: 'Vale alimentação',
  [EBenefitType.Snack]: 'Vale refeição',
  [EBenefitType.Fuel]: 'Vale combustível',
}

export const ADMINISTRATIVE_FEE_CALCULATION_TYPES = {
  [EFeeCalculationType.Item]: 'Colaborador',
  [EFeeCalculationType.Order]: 'Pedido',
}

export const ADMINISTRATIVE_FEE_VALUE_TYPES = {
  [EAdministrativeFeeValueType.Fixed]: 'Valor',
  [EAdministrativeFeeValueType.Percentual]: 'Percentual',
}
