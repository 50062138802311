import { useEffect, useState } from 'react'
import { FieldArrayWithId, useFieldArray, UseFormReturn } from 'react-hook-form'
import { faCheck, faPencil, faPlusCircle, faX } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button,
  ConfirmationDialog,
  Divider,
  Form,
  FormMaskedTextField,
  FormSelect,
  Grid,
  Icon,
  IconButton,
  Skeleton,
} from '@stationkim/front-ui'
import { ETipoTaxaEntrega } from '@/main/enums'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { rules } from './rules'

export type TDeliveryFee = {
  initialRange: string
  finalRange: string
  feeValue: string
  id?: string
}

interface DeliveryFeeProps {
  form: UseFormReturn<any>
  isLoading?: boolean
}

export type TForm = Record<string, any> & {
  deliveryFeeType: ETipoTaxaEntrega
  deliveryFees?: TDeliveryFee[]
  orderFeeValue?: string
}
type TField = FieldArrayWithId<TForm, 'deliveryFees', 'arrayId'>

export const DeliveryFee = ({ form, isLoading }: DeliveryFeeProps) => {
  const [editingFieldIndex, setEditingField] = useState<number>(null)
  const [prevField, setPrevField] = useState<TField>(null)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<number>(null)
  const { fields, append, remove } = useFieldArray<TForm, 'deliveryFees', 'arrayId'>({
    control: form.control,
    name: 'deliveryFees',
    keyName: 'arrayId',
  })

  const feeType = form.watch('deliveryFeeType')
  const [final, initial] = form.watch([
    `deliveryFees.${editingFieldIndex}.finalRange`,
    `deliveryFees.${editingFieldIndex}.initialRange`,
  ])

  const renderFields = () => {
    const fees = fields.map((field, index) => {
      const isBlocked = editingFieldIndex !== index
      const disabled = isBlocked && editingFieldIndex !== null
      return (
        <Grid item key={field.id} xs={12}>
          <Grid container spacing='16px'>
            <FormMaskedTextField
              mask={{
                mask: 'de num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                  },
                },
              }}
              unmask
              name={`deliveryFees.${index}.initialRange`}
              rules={rules(index).initialRange}
              gridProps={{
                xs: 12,
                sm: 3,
              }}
              blocked={isBlocked}
              size='small'
            />
            <FormMaskedTextField
              mask={{
                mask: '\\até num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                  },
                },
              }}
              unmask
              name={`deliveryFees.${index}.finalRange`}
              rules={rules(index).finalRange}
              gridProps={{
                xs: 12,
                sm: 3,
              }}
              blocked={isBlocked}
              size='small'
            />
            <FormMaskedTextField
              mask={'monetary'}
              name={`deliveryFees.${index}.feeValue`}
              unmask
              rules={rules(index).feeValue}
              gridProps={{
                xs: 12,
                sm: 3,
              }}
              blocked={isBlocked}
              size='small'
            />
            <Grid item xs sx={{ display: 'flex', gap: '16px' }}>
              <IconButton
                color={isBlocked ? 'primary' : 'success'}
                sx={{ marginTop: '14px' }}
                disabled={disabled}
                onClick={() => handleLeftButtonClick(index)}
              >
                <Icon icon={isBlocked ? faPencil : faCheck} />
              </IconButton>
              <IconButton
                color='error'
                sx={{ marginTop: '14px' }}
                disabled={disabled}
                onClick={() => handleRightButtonClick(field, index)}
              >
                <Icon icon={isBlocked ? faTrashCan : faX} />
              </IconButton>
            </Grid>
            <Grid item xs={12} sx={{ display: { xs: 'visible', sm: 'none' } }}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
      )
    })

    return (
      <Grid item xs={12}>
        <Grid container sx={{ maxHeight: { xs: 'fit-content', sm: '448px' }, overflow: 'auto' }} rowSpacing='24px'>
          {fees.length > 0 ? fees :
            <Box
              sx={(theme) => ({
                marginTop: '24px',
                padding: '16px 32px',
                border: '1px solid ' + theme.palette.grey[100],
                borderRadius: '4px',
              })}
            >
              Nenhuma taxa cadastrada no momento
            </Box>}
        </Grid>
      </Grid>
    )
  }

  const handleAddIntervalClick = () => {
    setEditingField(fields.length)
    append(
      {
        feeValue: '',
        finalRange: '',
        initialRange: '',
        id: '-1',
      },
      { shouldFocus: true },
    )
  }

  const handleLeftButtonClick = (index: number) => {
    if (editingFieldIndex === index) {
      if (Object.keys(form.formState.errors).length === 0) {
        const actualField = form.getValues('deliveryFees')[index]
        if (Object.keys(actualField).some((key) => actualField[key] === '')) {
          form.trigger('deliveryFees')
        } else {
          if (actualField.id === '-1') form.setValue(`deliveryFees.${index}`, { ...actualField, id: null })
          setEditingField(null)
        }
      }
    } else {
      setPrevField({ ...form.getValues().deliveryFees[index] })
      setEditingField(index)
    }
  }

  const handleRightButtonClick = (field: TField, index: number) => {
    if (editingFieldIndex === index && field.id !== '-1') {
      setEditingField(null)
      form.setValue(`deliveryFees.${index}`, { ...prevField })
    } else {
      setEditingField(null)
      setIsConfirmationOpen(index)
    }
  }

  useEffect(() => {
    form.trigger('deliveryFees')
  }, [form, final, initial])

  return (
    <Form form={form}>
      <ConfirmationDialog
        open={typeof isConfirmationOpen === 'number'}
        onCancel={() => setIsConfirmationOpen(null)}
        onClose={() => setIsConfirmationOpen(null)}
        onConfirm={() => remove(isConfirmationOpen)}
        title='Remover taxa'
        hideIcon
        color='error'
        innerContent={
          fields.length > 1 ?
            'As outras taxas permanecerão ativas e poderão ser editadas normalmente.' :
            'Atualmente, esta é a única taxa cadastrada. Após a remoção, nenhuma taxa estará ativa e será necessário cadastrar uma nova.'
        }
        confirmButtonLabel='Excluir'
      />
      {isLoading ? (
        <Grid item xs={12}>
          <Skeleton variant='rounded' height='40px' />
        </Grid>
      ) : (
        <>
          <FormSelect
            name='deliveryFeeType'
            label='Cálculo por'
            options={[
              { label: 'Número de cartões', value: ETipoTaxaEntrega.Cartao },
              { label: 'Pedido', value: ETipoTaxaEntrega.Pedido },
            ]}
            gridProps={{ xs: 12, sm: 5, md: 3, lg: 2 }}
          />
          {feeType === ETipoTaxaEntrega.Cartao && (
            <>
              {renderFields()}
              <Grid item xs={12}>
                <Button
                  variant='softText'
                  startIcon={<Icon icon={faPlusCircle} />}
                  disableTouchRipple
                  onClick={handleAddIntervalClick}
                  disabled={editingFieldIndex !== null}
                >
                  Adicionar intervalo
                </Button>
              </Grid>
            </>
          )}
          {feeType === ETipoTaxaEntrega.Pedido && (
            <>
              <Grid item xs={12}>
                <FormMaskedTextField
                  mask={'monetary'}
                  unmask
                  name={'orderFeeValue'}
                  //rules={rules(index).finalRange}
                  placeholder='R$ 00,00'
                  gridProps={{ xs: 12, sm: 5, md: 3, lg: 2 }}
                  size='small'
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Form>
  )
}
